.NaivgationControlGL {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #747881 !important;
  width: 30px;
}

.mapboxgl-ctrl-geocoder {
  background-color: #747881;
  color: white;
  :focus {
    color: white;
  }
  input {
    color: white;
  }
  svg {
    fill: white;
  }
}

.mapboxgl-ctrl-geocoder--pin-right button {
  background-color: #747881;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}

body {
  margin: 0px;
}
.mapboxgl-popup-content {
  padding: 0px !important;
  border-radius: 8px !important;
}

.mapboxgl-ctrl {
  width: 28px;
  position: absolute;
  bottom: 0;
}
.mapboxgl-ctrl-group {
  background-color: white;
  z-index: 10;
}

.mapboxgl-popup-anchor-bottom {
  z-index: 10;
}

.MuiBackdrop-root {
  opacity: 0 !important;
}

.mapboxgl-popup-tip {
  border-top-color: #1c1f26 !important;
}

// getting rid of zoom in/out button blue outline on focus
.mapboxgl-ctrl-group button:focus,
.mapboxgl-ctrl-group span:focus {
  outline: none !important;
  box-shadow: unset !important;
}

// map popup z-index so it does not get covered by the map dots
.mapboxgl-popup {
  z-index: 5 !important;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

// fix zoom bottom bg color
.mapboxgl-ctrl-group > button {
  background-color: white !important;
}
