.card {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  //padding: 30px;
  //overflow: scroll;
  max-height: 96vh;
  border-radius: 8px;
  padding: 0 !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}
