.show {
  height: 100%;
}
.panel__content {
  height: 100%;
}

.panel {
  transition: 0.3s;

  &.panel--collapse {
    height: 30px;

    &.panel--divider .panel__title,
    .panel__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.panel--success,
    &.panel--primary,
    &.panel--warning,
    &.panel--danger {
      height: 72px;
    }

    .panel__body {
      overflow: hidden;
      height: auto;
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &.panel--expand {
    height: 96vh;
    width: 74vw;
    right: 20px;
    top: 20px;
    position: fixed;
    z-index: 100;
    .panel__body {
      overflow: hidden;
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  &.panel--narrow {
    .panel__title {
      margin-bottom: 0;
    }
  }

  &.panel--divider {
    .panel__title {
      margin-bottom: 15px;
    }

    .panel__content {
      padding-top: 0px;
      padding-bottom: 40px;

      .tabs--bordered-bottom {
        .nav-item {
          border-bottom: 1px solid #dee2e6;
          .nav-link {
          }
        }
      }
    }
  }

  &.panel--success,
  &.panel--primary,
  &.panel--warning,
  &.panel--danger {
    .panel__body {
      overflow: hidden;
      padding: 0;
    }

    .panel__title {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;

      h5 {
        color: white;
      }
    }

    .panel__btns {
      z-index: 5;
      svg {
        fill: #ffffff;
      }
    }

    .panel__content {
      padding-top: 0px;
      padding-bottom: 40px;
    }
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;

    border-radius: 5px;
  }

  svg {
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
  }

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.panel__body {
  overflow: hidden;
  position: relative;

  padding-top: 30px;
  padding-bottom: 50px;
  transition: height 0.3s;
}

.panel__btns {
  position: absolute;
  z-index: 5;
  right: 0;
  margin-right: 5px;
}

.panel__btn {
  margin-right: 10px;
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 6;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    fill: #999;
  }

  &:focus {
    outline: none;
  }
}

.panel__title {
  text-transform: uppercase;
  margin-bottom: 5px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.panel__label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  padding: 3px 10px;
}

iframe {
  border-width: 0;
}
